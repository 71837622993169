
import { Component, Vue } from "vue-property-decorator";
import HorizontalRulerComponent from "../components/HorizontalRulerComponent.vue";
import QuotesComponent from "../components/QuotesComponent.vue";
import HeaderComponent from "../components/HeaderComponent.vue";
import MenuComponent from "../components/MenuComponent.vue";

@Component({
  components: {
    HorizontalRulerComponent,
    QuotesComponent,
    HeaderComponent,
    MenuComponent
  }
})
export default class TopHeaderComponent extends Vue {}
