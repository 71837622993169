import Vue from "vue";
import Vuex from "vuex";
import { Commit, GetterTree } from "vuex";
import AxiprClient from "@/modules/core/api/axiprClient";
import {
  AxiprState,
  Lid,
  Reunist,
  Quakip,
  Quote,
  Triumviraat,
  Activiteit,
  Foto
} from "./types";
import { loginStore } from "@/modules/members/store/login.state";

Vue.use(Vuex);

const state: AxiprState = {
  loading: false,
  leden: [],
  reunisten: [],
  quakipjes: [],
  quote: {},
  triumviraat: [],
  activiteiten: [],
  menuVisible: false
};

const getters: GetterTree<AxiprState, any> = {
  loading(state: AxiprState): boolean {
    return state.loading;
  },

  leden(state: AxiprState): Lid[] {
    return state.leden;
  },

  reunisten(state: AxiprState): Reunist[] {
    return state.reunisten;
  },

  quakipjes(state: AxiprState): Quakip[] {
    return state.quakipjes;
  },

  quote(state: AxiprState): Quote {
    return state.quote;
  },

  triumviraten(state: AxiprState): Triumviraat[] {
    return state.triumviraat;
  },

  activiteiten(state: AxiprState): Activiteit[] {
    return state.activiteiten;
  },

  menuVisible(state: AxiprState): boolean {
    return state.menuVisible;
  }
};

const actions = {
  async retrieveLeden({ commit }: { commit: Commit }) {
    commit("setLoading", true);
    const response = await AxiprClient.getLeden();
    commit("setLeden", response.data);
    commit("setLoading", false);
  },

  async retrieveReunisten({ commit }: { commit: Commit }) {
    commit("setLoading", true);
    const response = await AxiprClient.getReunisten();
    commit("setReunisten", response.data);
    commit("setLoading", false);
  },

  async retrieveQuakipjes({ commit }: { commit: Commit }) {
    commit("setLoading", true);
    const response = await AxiprClient.getQuakipjes();
    commit("setQuakipjes", response.data);
    commit("setLoading", false);
  },

  async retrieveQuote({ commit }: { commit: Commit }) {
    const response = await AxiprClient.getQuotes();
    commit("setQuote", [response.data.quote]);
  },

  async retrieveTriumviraten({ commit }: { commit: Commit }) {
    const response = await AxiprClient.getTriumviraten();
    commit("setTriumviraat", response.data);
  },

  async retrieveActiviteiten({ commit }: { commit: Commit }) {
    const response = await AxiprClient.retrieveData("activiteiten");

    const response2 = await AxiprClient.retrieveData("fotos");

    response.data.forEach((element: { id: number; fotos: Foto }) => {
      const fotos = response2.data.filter((foto: { activiteit: number }) => {
        return foto.activiteit === element.id;
      });
      element.fotos = fotos;
    });
    commit("setActiviteiten", response.data);
  }
};

const mutations = {
  setLoading(state: any, loading: boolean) {
    state.loading = loading;
  },

  setLeden(state: any, data: any) {
    state.leden = data;
  },

  setReunisten(state: any, data: any) {
    state.reunisten = data;
  },

  setQuakipjes(state: any, data: any) {
    state.quakipjes = data;
  },

  setQuote(state: any, data: any) {
    state.quote = data[0];
  },

  setTriumviraat(state: any, data: any) {
    state.triumviraat = data;
  },

  setActiviteiten(state: any, data: any) {
    state.activiteiten = data;
  },

  toggleMenu(state: any) {
    state.menuVisible = !state.menuVisible;
  }
};

export default new Vuex.Store({
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions,
  modules: {
    login: loginStore
  }
});
