var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"tinder"},[_c('div',{staticClass:"tinder__title-wrapper"},[_c('span',{staticClass:"title"},[_vm._v("Axipr Tinder")]),_c('div',{staticClass:"control"},[_c('Button',{attrs:{"type":"primary","iconLeft":"mdi mdi-abacus"},nativeOn:{"click":function($event){return _vm.showStatistics()}}},[_vm._v(" Statistieken bekijken ")]),(_vm.isAdmin)?_c('Button',{attrs:{"type":"primary","iconLeft":"mdi mdi-plus"},nativeOn:{"click":function($event){return _vm.createGeneration()}}},[_vm._v(" Eerstejaars toevoegen ")]):_vm._e(),(
          !(_vm.votes.length == 0 && _vm.history.length == 0 && _vm.queue.length == 0) &&
          _vm.isAdmin
        )?_c('Button',{attrs:{"type":"danger","iconLeft":"mdi mdi-delete"},nativeOn:{"click":function($event){return _vm.deleteGeneration()}}},[_vm._v(" Generatie verwijderen ")]):_vm._e()],1)]),(_vm.tinderAvailable)?_c('div',{staticClass:"tinder__text"},[_vm._v(" Welkom bij de Axipr Sjaarzentinder. Klik hier onder om de uitleg te bekijken. Veel plezier! ")]):_vm._e(),(_vm.tinderAvailable)?_c('div',{staticClass:"tinder__text"},[_vm._m(0)]):_vm._e(),_c('div',{staticClass:"tinder-big-wrapper"},[_c('div',{staticClass:"comment-section"},[_c('EerstejaarsCardComponent',{attrs:{"eerstejaars":_vm.highlightedEerstejaars}}),_c('div',{staticClass:"form"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.userComment),expression:"userComment"}],attrs:{"placeholder":"Typ hier je comment"},domProps:{"value":(_vm.userComment)},on:{"input":[function($event){if($event.target.composing)return;_vm.userComment=$event.target.value},function($event){_vm.commentSaved = false}]}}),_vm._v(" "),(!_vm.commentSaved)?_c('Button',{attrs:{"type":"tertiary"},nativeOn:{"click":function($event){return _vm.sendComment(_vm.highlightedEerstejaars)}}},[_vm._v(" Plaats comment ")]):_c('span',{staticClass:"message"},[_vm._v("Comment opgeslagen")])],1)],1),(_vm.queue.length)?_c('div',{staticClass:"container"},[_c('vue-tinder',{ref:"tinder",staticClass:"tinder__app",attrs:{"key-name":"id","queue":_vm.queue,"offset-y":14},on:{"update:queue":function($event){_vm.queue=$event},"submit":_vm.onSubmit},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"pic",style:({
              'background-image': `url(${scope.data.foto})`,
            })}),_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(scope.data.voornaam)+" "+_vm._s(scope.data.achternaam)+" - "+_vm._s(scope.data.bijnaam)+" ")])]}}],null,false,2547312202)},[_c('img',{staticClass:"like-pointer",attrs:{"slot":"like","src":require("@/assets/images/tinder/like-logo.png")},slot:"like"}),_c('img',{staticClass:"like-pointer",attrs:{"slot":"super","src":require("@/assets/images/tinder/like-logo.png")},slot:"super"})]),_c('div',{staticClass:"btns"},[_c('img',{attrs:{"src":require("@/assets/images/tinder/rewind.png")},on:{"click":function($event){return _vm.decide('rewind')}}}),_c('img',{attrs:{"src":require("@/assets/images/tinder/nope.png")},on:{"click":function($event){return _vm.decide('nope')}}}),_c('img',{attrs:{"src":require("@/assets/images/tinder/super-like.png")},on:{"click":function($event){return _vm.decide('super')}}}),_c('img',{attrs:{"src":require("@/assets/images/tinder/like.png")},on:{"click":function($event){return _vm.decide('like')}}}),_c('img',{attrs:{"src":require("@/assets/images/tinder/skip.png")},on:{"click":function($event){return _vm.decide('skip')}}})])],1):_vm._e(),_vm._m(1)]),(_vm.tinderAvailable)?_c('div',{staticClass:"cast-votes"},_vm._l((['superlike', 'like', 'dislike']),function(category,index){return _c('div',{key:index,staticClass:"category"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(category)+"s")]),(
          _vm.votes.filter((vote) => {
            return vote.vote == category;
          }).length
        )?_c('div',{staticClass:"eerstejaars"},_vm._l((_vm.votes.filter((vote) => {
            return vote.vote == category;
          })),function(eerstejaars,index){return _c('div',{key:index,staticClass:"eerstejaars-group"},[_c('EerstejaarsCardComponent',{attrs:{"eerstejaars":eerstejaars,"deletable":true},nativeOn:{"click":function($event){return _vm.deleteVote(eerstejaars.vote_id)}}}),(eerstejaars.comment)?_c('Comment',{attrs:{"comment":eerstejaars.comment},on:{"commentDeleted":function($event){return _vm.getCastVotes()}}}):_vm._e()],1)}),0):_c('div',{staticClass:"eerstejaars"},[_vm._v(" Je hebt nog geen "+_vm._s(category)+" uitgedeeld. ")])])}),0):_c('div',{staticClass:"tinder__empty"},[_vm._v(" Er is op dit moment geen tinder beschikbaar. ")])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('details',[_c('summary',[_vm._v("Uitleg")]),_vm._v(" Swipe naar links om een eerstejaars te disliken, naar rechts om te liken. Swipe naar boven om een superlike uit te delen. Je hebt onbeperkt aantal dislikes, likes en superlikes. Nadat je een stem hebt uitgebracht kun je één comment plaatsen. Als je je comment aan wil passen moet je je stem op deze eerstejaars eerst verwijderen en opnieuw stemmen. Je kunt een comment verwijderen in het overzicht, door op het prullenbakje te klikken. Statistieken kun je bekijken via de knop bovenaan. De resultaten zijn niet anoniem! "),_c('br'),_c('br'),_vm._v(" Gebruik de terugknop om terug te bladeren en je score eventueel aan te passen, dan wordt je score op deze eerstejaars overschreven. Je comment blijft altijd bewaard tot je deze zelf verwijderd. "),_c('br'),_c('br'),_vm._v(" Wil je alle of een specifieke eerstejaars opnieuw zien in de votingdeck? Kijk dan onderaan in de lijst met alle eerstejaars waar je op gestemd hebt, met de mogelijkheid om je stem te verwijderen. ")])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"comment-section mirror"},[_c('img',{attrs:{"src":require("@/assets/images/logo_dark.png"),"alt":"Axipr Logo"}})])
}]

export { render, staticRenderFns }