
import { Component, Vue } from "vue-property-decorator";
import BaseView from "@/modules/core/views/BaseView.vue";
import TriumviraatComponent from "@/modules/triumviraat/components/TriumviraatComponent.vue";

@Component({
  components: {
    BaseView,
    TriumviraatComponent
  }
})
export default class LedenView extends Vue {}
