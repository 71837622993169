
import { Component, Vue } from "vue-property-decorator";
import HorizontalRulerComponent from "@/modules/core/components/HorizontalRulerComponent.vue";
import { Foto } from "@/store/types";

@Component({
  components: {
    HorizontalRulerComponent
  }
})
export default class ActiviteitenComponent extends Vue {
  private text = "Selecteer een activiteit voor meer informatie en foto's!";
  private current = -1;
  private pictures: Foto[] = [];
  private mainPicture = "";

  public get getData() {
    return this.$store.getters["activiteiten"];
  }

  private activityClick(index: number) {
    this.current = index;
    this.pictures = this.$store.getters["activiteiten"][index].fotos;
    this.mainPicture = this.pictures.length
      ? this.$store.getters["activiteiten"][index].fotos[0].url
      : "";
    this.text = this.$store.getters["activiteiten"][index].beschrijving;
  }

  private pictureClick(picture: Foto) {
    this.mainPicture = picture.url;
  }
}
