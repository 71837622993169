"use strict";

import { AxiprState } from "@/store/types";
import { GetterTree } from "vuex";
import { LoginState, Lid } from "@/store/types";

export const getters: GetterTree<LoginState, AxiprState> = {
  user(state: LoginState): Lid | undefined {
    return state.user;
  },

  accessToken(state: LoginState): string | undefined {
    return state.accessToken;
  },

  // Calculated getters
  isAuthenticated(state: LoginState): boolean {
    return !!state.user;
  },

  isAdmin(state: LoginState): boolean {
    return state.user?.admin == true;
  }
};
