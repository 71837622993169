
import { Component, Vue } from "vue-property-decorator";
import TopHeaderComponent from "@/modules/core/components/TopHeaderComponent.vue";
import SidebarComponent from "@/modules/core/components/SidebarComponent.vue";
import FooterComponent from "@/modules/core/components/FooterComponent.vue";

@Component({
  components: {
    TopHeaderComponent,
    FooterComponent,
    SidebarComponent,
  },
})
export default class BaseView extends Vue {}
