import { LoginPost } from "@/store/types";
import { ApiClient } from "./apiClient";

const client = new ApiClient();

export default {
  getQuotes() {
    try {
      return client.get("/quotes/random");
    } catch (error) {
      throw error;
    }
  },

  getLeden() {
    try {
      return client.get("/leden?lid_type=lid");
    } catch (error) {
      throw error;
    }
  },

  getReunisten() {
    try {
      return client.get("/leden?lid_type=reunist");
    } catch (error) {
      throw error;
    }
  },

  getQuakipjes() {
    try {
      return client.get("/leden?lid_type=quakip");
    } catch (error) {
      throw error;
    }
  },

  getTriumviraten() {
    try {
      return client.get("/triumviraten");
    } catch (error) {
      throw error;
    }
  },

  retrieveData(data: string) {
    try {
      const parameters = new URLSearchParams();
      parameters.append("data", data);
      return client.get("", { params: parameters });
    } catch (error) {
      throw error;
    }
  },

  getTinderStatistics() {
    try {
      return client.get("/tinder/statistieken");
    } catch (error) {
      throw error;
    }
  },

  login(credentials: LoginPost) {
    const formData = new FormData();
    formData.append("username", credentials.username);
    formData.append("password", credentials.password);
    try {
      return client.post("/login", formData);
    } catch (error) {
      throw error;
    }
  },

  logout() {
    try {
      return client.post("/logout");
    } catch (error) {
      throw error;
    }
  },

  getVotingDeck() {
    try {
      return client.get("/tinder");
    } catch (error) {
      throw error;
    }
  },

  vote(id: number, vote: string) {
    vote === "nope"
      ? (vote = "dislike")
      : vote === "super"
      ? (vote = "superlike")
      : (vote = "like");
    const formData = new FormData();
    formData.append("vote", vote);
    try {
      return client.post(`/tinder/vote/${id}`, formData);
    } catch (error) {
      throw error;
    }
  },

  deleteVote(id: number) {
    try {
      return client.delete(`/tinder/vote/${id}`);
    } catch (error) {
      throw error;
    }
  },

  getCastVotes() {
    try {
      return client.get("/tinder/votes");
    } catch (error) {
      throw error;
    }
  },

  deleteGeneration() {
    try {
      return client.delete("/tinder");
    } catch (error) {
      throw error;
    }
  },

  eerstejaarsToevoegen(eerstejaars: any[]) {
    try {
      return client.post(
        "/tinder/eerstejaars",
        {
          eerstejaars: eerstejaars,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    } catch (error) {
      throw error;
    }
  },

  comment(id: number, comment: string) {
    const formData = new FormData();
    formData.append("comment", comment);
    try {
      return client.post(`/tinder/comment/${id}`, formData);
    } catch (error) {
      throw error;
    }
  },

  deleteComment(id: number) {
    try {
      return client.delete(`/tinder/comment/${id}`);
    } catch (error) {
      throw error;
    }
  },

  requestNewPassword(username: string) {
    const formData = new FormData();
    formData.append("username", username);
    try {
      return client.post(`/forgot-password`, formData);
    } catch (error) {
      throw error;
    }
  },

  setNewPassword(
    token: string,
    password: string,
    passwordConfirmation: string
  ) {
    const formData = new FormData();
    formData.append("token", token);
    formData.append("password", password);
    formData.append("password_confirmation", passwordConfirmation);
    try {
      return client.post(`/reset-password`, formData);
    } catch (error) {
      throw error;
    }
  },
};
