
import { Component, Vue } from "vue-property-decorator";
import BaseView from "@/modules/core/views/BaseView.vue";
import HomeComponent from "@/modules/home/components/HomeComponent.vue";

@Component({
  components: {
    BaseView,
    HomeComponent
  }
})
export default class HomeView extends Vue {}
