
import { Component, Vue } from "vue-property-decorator";
import Button from "../../core/components/Button.vue";
import axiprClient from "@/modules/core/api/axiprClient";
import { Getter } from "vuex-class";

@Component({
  components: {
    Button,
  },
})
export default class NewGenerationComponent extends Vue {
  @Getter("login/isAdmin") private isAdmin!: any;

  private key = 0;
  private eerstejaars: {
    voornaam: string;
    bijnaam: string;
    achternaam: string;
    foto: string | null;
  }[] = Array(10)
    .fill(null)
    .map(() => ({
      voornaam: "",
      bijnaam: "",
      achternaam: "",
      foto: null,
    }));

  private handleFileUpload(event: any, person: any) {
    const files = event.target.files;

    if (files.length > 0) {
      const fileReader = new FileReader();
      fileReader.onload = (e) => {
        const base64Data = e.target!.result;
        person.foto = base64Data;
      };
      fileReader.readAsDataURL(files[0]);
    }
  }

  private cancel() {
    this.$router.push({ name: "Tinder" });
  }

  private async saveAndBack() {
    await axiprClient.eerstejaarsToevoegen(
      this.removeEmptyEntries(this.eerstejaars)
    );
    this.$router.push({ name: "Tinder" });
  }

  private async saveAndAddMore() {
    try {
      const response = await axiprClient.eerstejaarsToevoegen(
        this.removeEmptyEntries(this.eerstejaars)
      );

      this.eerstejaars = Array(10)
        .fill(null)
        .map(() => ({
          voornaam: "",
          bijnaam: "",
          achternaam: "",
          foto: null,
        }));
      this.key++;
    } catch (error) {
      window.alert(
        "An error occurred while saving: " +
          (error as any).response!.data.message
      );
    }
  }

  private removeEmptyEntries(eerstejaars: any[]) {
    return eerstejaars.filter((entry) => {
      return (
        entry.voornaam.trim() !== "" ||
        entry.bijnaam.trim() !== "" ||
        entry.achternaam.trim() !== "" ||
        entry.foto !== null
      );
    });
  }
}
