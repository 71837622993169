"use strict";

import { Module } from "vuex";
import { AxiprState, LoginState } from "@/store/types";
import { actions } from "@/modules/members/store/login.actions";
import { mutations } from "@/modules/members/store/login.mutations";
import { getters } from "@/modules/members/store/login.getters";

const namespaced = true;
export const state: LoginState = {};

export const loginStore: Module<LoginState, AxiprState> = {
  namespaced,
  state,
  mutations,
  actions,
  getters
};
