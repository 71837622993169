
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class EerstejaarsCardComponent extends Vue {
  @Prop() private eerstejaars: any;
  @Prop({ default: false }) private deletable!: boolean;
}
