
import { Component, Vue } from "vue-property-decorator";
import HorizontalRulerComponent from "../components/HorizontalRulerComponent.vue";
import FooterContentComponent from "../components/FooterContentComponent.vue";

@Component({
  components: {
    HorizontalRulerComponent,
    FooterContentComponent,
  },
})
export default class FooterComponent extends Vue {}
