
import { Component, Vue } from "vue-property-decorator";
import HorizontalRulerComponent from "@/modules/core/components/HorizontalRulerComponent.vue";
import store from "@/store";

@Component({
  components: {
    HorizontalRulerComponent
  }
})
export default class SidebarComponent extends Vue {
  private navigate(routeName: string) {
    store.commit("toggleMenu");
    this.$router.push({ name: routeName });
  }

  private getActiveClass(routeName: string) {
    return this.$route.name === routeName ? "active" : "";
  }

  private exitMenu() {
    store.commit("toggleMenu");
  }
}
