
import { DisplayType } from "@/store/types";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {}
})
export default class Button extends Vue {
  @Prop() type?: DisplayType;
  @Prop() iconLeft?: string;
  @Prop() iconRight?: string;
  @Prop({ default: false, type: Boolean }) ghost!: boolean;
  @Prop({ default: false, type: Boolean }) rounded!: boolean;
  @Prop({ default: false, type: Boolean }) hideTextOnMobile!: boolean;
  @Prop({ default: false, type: Boolean }) active!: boolean;
  @Prop({ default: false, type: Boolean }) disabled!: boolean;

  private get classObject() {
    return {
      type: this.type,
      ghost: this.ghost
    };
  }
}
