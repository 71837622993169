
import { Component, Vue } from "vue-property-decorator";
import Button from "../../core/components/Button.vue";
import axiprClient from "@/modules/core/api/axiprClient";

@Component({
  components: {
    Button,
  },
})
export default class PasswordResetComponent extends Vue {
  private password = "";
  private password_confirmation = "";
  private message: string | null = null;

  private async setNewPassword() {
    this.message = null;
    try {
      const response = await axiprClient.setNewPassword(
        this.$route.params.token,
        this.password,
        this.password_confirmation
      );
      this.message = "Je wachtwoord is succesvol gewijzigd.";
    } catch (error) {
      if ((error as any).response.status === 422) {
        this.message = "Het wachtwoord voldoet niet aan de eisen.";
      } else {
        this.message = "Er is iets misgegaan. Probeer het later nog eens.";
      }
    }
  }

  private async goBack() {
    this.$router.push({ name: "Voor-Leden" });
  }
}
