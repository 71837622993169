import Vue from "vue";
import VueRouter, { Route, RouteConfig } from "vue-router";
import HomeView from "@/modules/home/views/HomeView.vue";
import LedenView from "@/modules/leden/views/LedenView.vue";
import TriumviraatView from "@/modules/triumviraat/views/TriumviraatView.vue";
import ActiviteitenView from "@/modules/activiteiten/views/ActiviteitenView.vue";
import MemberView from "@/modules/members/views/MemberView.vue";
import TinderView from "@/modules/tinder/views/TinderView.vue";
import TinderComponent from "@/modules/tinder/components/TinderComponent.vue";
import NewGenerationComponent from "@/modules/tinder/components/NewGenerationComponent.vue";
import StatisticsComponent from "@/modules/tinder/components/StatisticsComponent.vue";
import MemberComponent from "@/modules/members/components/MemberComponent.vue";
import ForgotPasswordComponent from "@/modules/members/components/ForgotPasswordComponent.vue";
import PasswordResetComponent from "@/modules/members/components/PasswordResetComponent.vue";
import store from "@/store";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    meta: {
      title: "Home",
    },
    beforeEnter: (to, from, next) => {
      store.dispatch("retrieveQuote", {});
      next();
    },
    component: HomeView,
  },
  {
    path: "/leden",
    name: "Leden",
    meta: {
      dataset: "leden",
      title: "Axipri",
    },
    beforeEnter: (to, from, next) => {
      store.dispatch("retrieveLeden", {});
      store.dispatch("retrieveQuote", {});
      next();
    },
    component: LedenView,
  },
  {
    path: "/quakipjes",
    name: "Quakipjes",
    meta: {
      dataset: "quakipjes",
      title: "Quakipjes",
    },
    beforeEnter: (to, from, next) => {
      store.dispatch("retrieveQuakipjes", {});
      store.dispatch("retrieveQuote", {});
      next();
    },
    component: LedenView,
  },
  {
    path: "/reunisten",
    name: "Reunisten",
    meta: {
      dataset: "reunisten",
      title: "Reunisten",
    },
    beforeEnter: (to, from, next) => {
      store.dispatch("retrieveReunisten", {});
      store.dispatch("retrieveQuote", {});
      next();
    },
    component: LedenView,
  },
  {
    path: "/triumviraat",
    name: "Triumviraat",
    meta: {
      dataset: "triumviraat",
      title: "Triumviraat",
    },
    beforeEnter: (to, from, next) => {
      store.dispatch("retrieveTriumviraten", {});
      store.dispatch("retrieveQuote", {});
      next();
    },
    component: TriumviraatView,
  },
  {
    path: "/activiteiten",
    name: "Activiteiten",
    meta: {
      title: "Activiteiten",
    },
    beforeEnter: (to, from, next) => {
      store.dispatch("retrieveActiviteiten", {});
      store.dispatch("retrieveQuote", {});
      next();
    },
    component: ActiviteitenView,
  },
  {
    path: "/tinder",
    children: [
      {
        path: "",
        component: TinderComponent,
        name: "Tinder",
        meta: {
          title: "Tinder",
        },
      },
      {
        path: "nieuwe-generatie",
        name: "Tinder Nieuwe Generatie",
        component: NewGenerationComponent,
        meta: {
          title: "Tinder - Nieuwe generatie",
        },
        beforeEnter: (to, from, next) => {
          if (store.getters["login/isAdmin"]) {
            next();
          } else {
            next({ name: "Voor-Leden" });
          }
        },
      },
      {
        path: "statistieken",
        name: "Tinder Statistieken",
        component: StatisticsComponent,
        meta: {
          title: "Tinder - Statistieken",
        },
      },
    ],
    beforeEnter: (to, from, next) => {
      store.dispatch("login/INIT");
      store.dispatch("retrieveQuote", {});
      if (store.getters["login/isAuthenticated"]) {
        next();
      } else {
        next({ name: "Voor-Leden" });
      }
    },
    component: TinderView,
  },
  {
    path: "/voor-leden",
    children: [
      {
        path: "",
        component: MemberComponent,
        name: "Voor-Leden",
        meta: {
          title: "Voor Leden",
        },
      },
      {
        path: "wachtwoord-vergeten",
        component: ForgotPasswordComponent,
        name: "Wachtwoord-Vergeten",
        meta: {
          title: "Wachtwoord Vergeten",
        },
      },
      {
        path: "wachtwoord-resetten/:token",
        component: PasswordResetComponent,
        name: "Wachtwoord-Resetten",
        meta: {
          title: "Wachtwoord Resetten",
        },
      },
    ],
    beforeEnter: async (to, from, next) => {
      store.dispatch("retrieveQuote", {});
      await store.dispatch("login/INIT");
      if (store.getters["login/isAuthenticated"]) {
        next({
          name: "Tinder",
        });
      } else {
        next();
      }
    },
    component: MemberView,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to: Route, from: Route, next: (param?: any) => void) => {
  store.dispatch("login/INIT");
  next();
});

router.afterEach((to) => {
  if (to.meta?.title) {
    document.title = "Axipr - " + to.meta.title;
  } else {
    document.title = "Axipr";
  }
});

export default router;
