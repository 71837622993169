
import { Component, Prop, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import axiprClient from "../api/axiprClient";

@Component({
  components: {},
})
export default class Comment extends Vue {
  @Prop() private comment!: any;
  @Getter('login/user') private user!: any;

  private async deleteComment() {
    await axiprClient.deleteComment(this.comment.id);
    this.$emit("commentDeleted");
  }

  private get canDelete() {
    return this.user.id === this.comment.lid_id;
  }
}
