"use strict";

import { MutationTree } from "vuex";
import { LoginState, Lid } from "@/store/types";
import Vue from "vue";

export const mutations: MutationTree<LoginState> = {
  setUser(state: LoginState, user: Lid) {
    Vue.set(state, "user", user);
  },

  resetUser(state: LoginState) {
    Vue.set(state, "user", undefined);
  },

  accessToken(state: LoginState, token: string | undefined) {
    Vue.set(state, "accessToken", token);
  }
};
