"use strict";

import {
  AxiprState,
  Lid,
  LoginPost,
  LoginResponse,
  LoginState,
  TokenPayload,
} from "@/store/types";
import { ActionTree, Commit } from "vuex";
import axiprClient from "@/modules/core/api/axiprClient";
import { state } from "@/modules/members/store/login.state";
import jwtDecode from "jwt-decode";
import Cookies from "js-cookie";

const LS_JWT_KEY = "access_token";

export const actions: ActionTree<LoginState, AxiprState> = {
  // INIT({ dispatch }) {
  //   if (!state.accessToken) {
  //     const token = localStorage.getItem(LS_JWT_KEY);
  //     if (token) {
  //       dispatch("SET_ACCESS_TOKEN", token);
  //     dispatch("PARSE_ACCESS_TOKEN");
  //     }
  //   }
  // },

  SET_USER({ commit }, user: Lid) {
    commit("setUser", user);
  },

  RESET_USER({ commit }) {
    commit("resetUser");
  },

  SET_ACCESS_TOKEN({ commit }, token: string) {
    commit("accessToken", token);
  },

  CLEAR_ACCESS_TOKEN({ commit }) {
    commit("accessToken", undefined);
  },

  async LOGIN({ dispatch }, credentials: LoginPost): Promise<void> {
    try {
      const response: LoginResponse = (await axiprClient.login(credentials))
        .data;
      localStorage.setItem(LS_JWT_KEY, response.token);
      dispatch("SET_ACCESS_TOKEN", response.token);
      dispatch("PARSE_ACCESS_TOKEN");
    } catch (err) {
      throw err;
    }
  },

  async LOGOUT({ dispatch }) {
    localStorage.removeItem(LS_JWT_KEY);
    dispatch("RESET_USER");
    dispatch("CLEAR_ACCESS_TOKEN");
    try {
      await axiprClient.logout();
    } catch (err) {
      // throw err;
    }
  },

  PARSE_ACCESS_TOKEN({ dispatch }) {
    if (state.accessToken) {
      const payload = JSON.parse(
        atob(state.accessToken.split(".")[1])
      ) as TokenPayload;
      dispatch("SET_USER", payload.usr);
    }
  },

  INIT({ dispatch }) {
    if (!state.user) {
      const token = Cookies.get("jwt");
      if (token) {
        const decodedToken: any = jwtDecode(token);
        const time = Math.floor(new Date().getTime() / 1000);
        if (time >= decodedToken.auth.exp) {
          Cookies.remove("jwt");
          dispatch("RESET_USER");
          dispatch("CLEAR_ACCESS_TOKEN");
        } else {
          dispatch("SET_ACCESS_TOKEN", token);
          dispatch("PARSE_ACCESS_TOKEN");
        }
      }
    }
  },
};
