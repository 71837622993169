import axios, { AxiosInstance, AxiosResponse, AxiosRequestConfig } from "axios";

/**
 * Create a new Axios client instance
 */
const getClient = (baseURL = process.env.VUE_APP_API_URL) => {
  const options = {
    baseURL,
    withCredentials: true
  };

  const client = axios.create(options);

  return client;
};

class ApiClient {
  private client: AxiosInstance;

  constructor(baseURL = process.env.VUE_APP_API_URL) {
    this.client = getClient(baseURL);
  }

  public async get(url: string, config: AxiosRequestConfig = {}) {
    try {
      return await this.client.get(url, config);
    } catch (error) {
      throw error;
    }
  }

  public async post(
    url: string,
    data: any = {},
    config: AxiosRequestConfig = {}
  ): Promise<AxiosResponse> {
    try {
      return await this.client.post(url, data, config);
    } catch (error) {
      throw error;
    }
  }

  public async delete(
    url: string,
    config: AxiosRequestConfig = {}
  ): Promise<AxiosResponse> {
    try {
      return await this.client.delete(url, config);
    } catch (error) {
      throw error;
    }
  }
}

export { ApiClient };
