
import { Component, Vue } from "vue-property-decorator";
import Button from "../../core/components/Button.vue";
import axiprClient from "@/modules/core/api/axiprClient";

@Component({
  components: {
    Button,
  },
})
export default class ForgotPasswordComponent extends Vue {
  private username = "";
  private message: string | null = null;

  private async requestNewPassword() {
    this.message = null;
    const response = await axiprClient.requestNewPassword(this.username);
    if (response.status === 200) {
      this.message = "Er is een e-mail verstuurd met een link om je wachtwoord te resetten.";
    } else {
      this.message = "Er is iets misgegaan. Probeer het later nog eens.";
    }
  }

  private async goBack() {
    this.$router.push({ name: "Voor-Leden" });
  }
}
