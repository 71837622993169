
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class MenuComponent extends Vue {
  private navigate(routeName: string) {
    this.$router.push({ name: routeName });
  }

  private getActiveClass(routeName: string) {
    return this.$route.name === routeName ? "active" : "";
  }
}
