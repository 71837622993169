
import { Component, Vue } from "vue-property-decorator";
import LidCardComponent from "../components/LidCardComponent.vue";

@Component({
  components: {
    LidCardComponent
  }
})
export default class LedenComponent extends Vue {
  public dataset = "";

  public get loading() {
    return this.$store.getters["loading"];
  }

  public get getData() {
    this.dataset = this.$route.meta?.dataset;
    return this.$store.getters[this.dataset];
  }
}
