
import { Component, Vue, Prop } from "vue-property-decorator";
import HorizontalRulerComponent from "@/modules/core/components/HorizontalRulerComponent.vue";
import moment from "moment";
import { Lid, Quakip, Reunist } from "@/store/types";

@Component({
  components: {
    HorizontalRulerComponent,
  },
})
export default class LidCardComponent extends Vue {
  @Prop() data!: Lid | Reunist | Quakip;

  public getFullName() {
    let name = this.data.voornaam;
    this.data.bijnaam
      ? (name += ' "' + this.data.bijnaam + '" ')
      : (name += " ");
    name += this.data.achternaam;
    return name;
  }

  public getFormattedDateBirth() {
    return moment(this.data.geboortedatum).locale("nl").format("D MMMM YYYY");
  }

  public getFormattedDateDeath() {
    return moment((this.data as Reunist).sterfdatum)
      .locale("nl")
      .format("D MMMM YYYY");
  }

  public getAge() {
    let age;
    if ((this.data as Reunist).sterfdatum) {
      age = moment((this.data as Reunist).sterfdatum).diff(
        this.data.geboortedatum,
        "years"
      );
    } else {
      age = moment().diff(this.data.geboortedatum, "years");
    }
    return age;
  }

  public getEmail() {
    let base = this.data.bijnaam ?? this.data.voornaam;
    base = base.toLowerCase();
    return base + "@axipr.nl";
  }
}
