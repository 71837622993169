
import { Component, Vue } from "vue-property-decorator";
import HorizontalRulerComponent from "@/modules/core/components/HorizontalRulerComponent.vue";
import confetti from "canvas-confetti";

@Component({
  components: {
    HorizontalRulerComponent
  }
})
export default class HomeComponent extends Vue {
  private duration = 15 * 1000;
  private animationEnd = Date.now() + this.duration;
  private defaults = {
    startVelocity: 30,
    spread: 360,
    ticks: 80,
    zIndex: 0,
    colors: ["#0000ff", "#ffff00"]
  };

  private randomInRange(min: number, max: number) {
    return Math.random() * (max - min) + min;
  }

  private created() {
    const date = new Date();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    if (month == 1 && day == 23) {
      const interval = setInterval(() => {
        const timeLeft = this.animationEnd - Date.now();

        if (timeLeft <= 0) {
          return clearInterval(interval);
        }

        const particleCount = 250 * (timeLeft / this.duration);
        // since particles fall down, start a bit higher than random
        confetti(
          Object.assign({}, this.defaults, {
            particleCount,
            origin: { x: this.randomInRange(0.1, 0.3), y: Math.random() - 0.2 }
          })
        );
        confetti(
          Object.assign({}, this.defaults, {
            particleCount,
            origin: { x: this.randomInRange(0.7, 0.9), y: Math.random() - 0.2 }
          })
        );
      }, 250);
    }
  }
}
