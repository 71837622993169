
import { Component, Vue } from "vue-property-decorator";
import AxiprClient from "@/modules/core/api/axiprClient";
import VueTinder from "vue-tinder";
import Button from "../../core/components/Button.vue";
import Comment from "../../core/components/Comment.vue";
import { Getter } from "vuex-class";
import EerstejaarsCardComponent from "./EerstejaarsCardComponent.vue";

@Component({
  components: {
    VueTinder,
    Button,
    EerstejaarsCardComponent,
    Comment,
  },
})
export default class TinderComponent extends Vue {
  @Getter("login/isAdmin") private isAdmin!: any;

  private queue: any[] = [];
  private history: any[] = [];
  private votes: any[] = [];

  private highlightedEerstejaars = null;
  private userComment = "";
  private commentSaved = false;

  private get tinderAvailable() {
    return this.queue.length || this.history.length || this.votes.length;
  }

  private async onSubmit({ type, key, item }: any) {
    this.history.push(item);
    this.highlightedEerstejaars = item;
    this.userComment = "";
    this.commentSaved = false;
    await AxiprClient.vote(key.slice(1), type);
    if (this.queue.length < 1) {
      await this.renewVotingDeck();
    }
    await this.getCastVotes();
  }

  private async getCastVotes() {
    const response = await AxiprClient.getCastVotes();
    this.votes = response.data.data;
  }

  private async deleteVote(id: number) {
    await AxiprClient.deleteVote(id);
    await this.getCastVotes();
    await this.renewVotingDeck();
  }

  private async beforeMount() {
    await this.renewVotingDeck();
    await this.getCastVotes();
  }

  private async renewVotingDeck() {
    const response = await AxiprClient.getVotingDeck();
    this.queue = response.data.data;
    this.queue.forEach((element) => {
      element.id = `t${element.id}`;
    });
  }

  private decide(choice: any) {
    if (choice === "rewind") {
      if (this.history.length) {
        (this.$refs.tinder as any).rewind([this.history.pop()]);
      }
      return;
    }
    if (choice === "skip") {
      if (this.queue.length > 1) {
        this.queue.shift();
        return;
      }
      this.renewVotingDeck();
      return;
    }
    (this.$refs.tinder as any).decide(choice);
  }

  private showStatistics() {
    this.$router.push({ name: "Tinder Statistieken" });
  }

  private async deleteGeneration() {
    const userConfirmed = window.confirm(
      "Weet je zeker dat je deze generatie wilt verwijderen? Alle namen, foto's, votes en comments worden verwijderd! Dit kan niet ongedaan gemaakt worden (seriously!)!"
    );
    if (userConfirmed) {
      await AxiprClient.deleteGeneration();
      this.queue = [];
      this.history = [];
      this.votes = [];
    }
  }

  private createGeneration() {
    this.$router.push({ name: "Tinder Nieuwe Generatie" });
  }

  private async sendComment(eerstejaars: any) {
    const response = await AxiprClient.comment(
      eerstejaars.id.slice(1),
      this.userComment
    );
    if (response.status === 201) {
      this.commentSaved = true;
      await this.getCastVotes();
    } else {
      alert(
        "Er is iets misgegaan met het opslaan van je comment. Probeer het later nog eens."
      );
    }
  }
}
