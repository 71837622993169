
import { Component, Vue } from "vue-property-decorator";
import HorizontalRulerComponent from "@/modules/core/components/HorizontalRulerComponent.vue";
import TriumviraatCardComponent from "@/modules/triumviraat/components/TriumviraatCardComponent.vue";
import { Triumviraat } from "@/store/types";

@Component({
  components: {
    HorizontalRulerComponent,
    TriumviraatCardComponent
  }
})
export default class TriumviraatComponent extends Vue {
  public get getData(): Triumviraat[] {
    return this.$store.getters["triumviraten"];
  }
}
