
import store from "@/store";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class QuotesComponent extends Vue {
  public get getQuote() {
    return this.$store.getters["quote"];
  }

  private hamburgerClicked() {
    store.commit("toggleMenu");
  }

  private get loggedIn() {
    return store.getters["login/isAuthenticated"];
  }

  private async logout() {
    await store.dispatch("login/LOGOUT");
    this.$router.push({ name: "Home" });
  }
}
