
import { Component, Vue } from "vue-property-decorator";
import Button from "../../core/components/Button.vue";
import { LoginPost } from "@/store/types";

@Component({
  components: {
    Button,
  },
})
export default class MemberComponent extends Vue {
  private username = "";
  private password = "";
  private message: string | null = null;

  private async login() {
    this.message = null;
    const credentials: LoginPost = {
      username: this.username,
      password: this.password,
    };
    try {
      await this.$store.dispatch("login/LOGIN", credentials);
      this.$router.push({ name: "Tinder" });
    } catch (error) {
      this.message = "Gebruikersnaam / wachtwoord combinatie incorrect.";
    }
  }

  private async forgotPassword() {
    this.$router.push({ name: "Wachtwoord-Vergeten" });
  }
}
