import { render, staticRenderFns } from "./LedenComponent.vue?vue&type=template&id=116fee54&scoped=true&"
import script from "./LedenComponent.vue?vue&type=script&lang=ts&"
export * from "./LedenComponent.vue?vue&type=script&lang=ts&"
import style0 from "./LedenComponent.vue?vue&type=style&index=0&id=116fee54&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "116fee54",
  null
  
)

export default component.exports