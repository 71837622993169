
import { Component, Vue } from "vue-property-decorator";
import HorizontalRulerComponent from "../components/HorizontalRulerComponent.vue";

@Component({
  components: {
    HorizontalRulerComponent,
  },
})
export default class FooterContentComponent extends Vue {}
