
import { Component, Vue, Prop } from "vue-property-decorator";
import HorizontalRulerComponent from "@/modules/core/components/HorizontalRulerComponent.vue";
import PersoonCardComponent from "./PersoonCardComponent.vue";
import { Triumviraat } from "@/store/types";

@Component({
  components: {
    HorizontalRulerComponent,
    PersoonCardComponent
  }
})
export default class TriumviraatComponent extends Vue {
  @Prop() data!: Triumviraat;
  @Prop({ type: Boolean, default: false }) odd!: boolean;
}
