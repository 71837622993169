
import { Component, Vue } from "vue-property-decorator";
import AxiprClient from "@/modules/core/api/axiprClient";
import { Getter } from "vuex-class";
import { EerstejaarsStatistic } from "@/store/types";
import EerstejaarsCardComponent from "./EerstejaarsCardComponent.vue";
import Button from "@/modules/core/components/Button.vue";
import Comment from "@/modules/core/components/Comment.vue";

@Component({
  components: {
    EerstejaarsCardComponent,
    Button,
    Comment,
  },
})
export default class StatisticsComponent extends Vue {
  @Getter("login/isAdmin") private isAdmin!: any;

  private eerstejaars: EerstejaarsStatistic[] = [];

  private async beforeMount() {
    await this.getTinderStatistics();
  }

  private async getTinderStatistics() {
    const response = await AxiprClient.getTinderStatistics();
    this.eerstejaars = response.data;
  }

  private printStatistics() {
    window.print();
  }

  private goBack() {
    this.$router.push({ name: "Tinder" });
  }

  private categoryImage(category: string) {
    switch (category) {
      case "superlikes":
        return require("@/assets/images/tinder/super-like.png");
      case "likes":
        return require("@/assets/images/tinder/like.png");
      case "dislikes":
        return require("@/assets/images/tinder/nope.png");
      default:
        return require("@/assets/images/tinder/nope.png");
    }
  }
}
