
import { Component, Vue } from "vue-property-decorator";
import BaseView from "@/modules/core/views/BaseView.vue";
import ActiviteitenComponent from "@/modules/activiteiten/components/ActiviteitenComponent.vue";

@Component({
  components: {
    BaseView,
    ActiviteitenComponent
  }
})
export default class HomeView extends Vue {}
