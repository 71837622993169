
import { Component, Vue } from "vue-property-decorator";
import BaseView from "@/modules/core/views/BaseView.vue";

@Component({
  components: {
    BaseView
  }
})
export default class MemberView extends Vue {}
