
import { Component, Vue } from "vue-property-decorator";
import BaseView from "@/modules/core/views/BaseView.vue";
import LedenComponent from "@/modules/leden/components/LedenComponent.vue";

@Component({
  components: {
    BaseView,
    LedenComponent
  }
})
export default class LedenView extends Vue {}
